import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { fetchSearch } from "../../Services/index.ts";
import { Props } from "../../types/index.ts";
import { Avatar, Box, ImageListItemBar, Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";

export default function Search() {
  const { name } = useParams();

  const [movies, setMovies] = React.useState<Props[]>([]);
  const [loading, setLoading] = React.useState(false);

  const useRefDiv = React.useRef<HTMLDivElement>(null);

  const handleClick = (id: number, name: string, titleprop: string) => {
    const title = (titleprop ?? name)
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");

    if (name) {
      window.location.href = `/serie/${id}/assistir-${title}`;
    } else {
      window.location.href = `/filme/${id}/assistir-${title}`;
    }
  };

  const loadMovies = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const { movie, serie } = await fetchSearch(name);

      if (movie) {
        setMovies((prevMovies) => {
          const newMovies = movie?.results.filter(
            (newMovie) => !prevMovies.some((movie) => movie.id === newMovie.id)
          );
          return [...prevMovies, ...newMovies];
        });
      }

      if (serie) {
        setMovies((prevMovies) => {
          const newSeries = serie?.results.filter(
            (newMovie) => !prevMovies.some((movie) => movie.id === newMovie.id)
          );
          return [...prevMovies, ...newSeries];
        });
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadMovies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      ref={useRefDiv}
      sx={{ display: "flex", flexDirection: "column", paddingInline: 1 }}
    >
      <h1>Resultados para: {name}</h1>
      <p>resultados encontrados: {movies.length}</p>
      <ImageList
        sx={{
          width: "100%",

          paddingLeft: 0,
          scrollBehavior: "smooth",
          position: "relative",
          display: "grid",

          gridTemplateColumns: {
            xl: "repeat(8, 1fr) !important",
            sm: "repeat(6, 1fr) !important",
            xs: "repeat(4, 1fr) !important",
          },
          "@media (max-width: 1500px) and (min-width: 1300px)": {
            gridTemplateColumns: "repeat(7, 1fr) !important",
          },
          "@media (max-width: 760px) and (min-width: 600px)": {
            gridTemplateColumns: "repeat(5, 1fr) !important",
          },
          "@media (max-width: 400px)": {
            gridTemplateColumns: "repeat(3, 1fr) !important",
          },
        }}
      >
        {loading &&
          Array.from({ length: 8 }).map((_, index) => (
            <Skeleton
              key={index}
              sx={{ bgcolor: "grey.900" }}
              variant="rectangular"
              height={300}
            />
          ))}
        {movies.length > 0
          ? movies.map((item, key) => (
              <ImageListItem
                key={key}
                onClick={() => handleClick(item.id, item.name, item.title)}
                sx={{
                  cursor: "pointer",
                  maxWidth: {
                    sm: 220,
                  },
                  maxHeight: "300px",
                  marginBlockEnd: {
                    xl: "30px",
                  },
                  "@media (max-width: 1700px) and (min-width: 1600px)": {
                    marginBlockEnd: "20px",
                  },
                  "@media (max-width: 1599px) and (min-width: 1300px)": {
                    marginBlockEnd: "10px",
                  },
                }}
              >
                <Avatar
                  sx={{
                    bgcolor:
                      item.vote_average >= 7
                        ? "green"
                        : item.vote_average >= 5
                        ? "#b0b000bf"
                        : "red",
                    position: "absolute",
                    top: 5,
                    right: 6,
                    width: "51px",
                    height: "25px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  variant="rounded"
                >
                  <StarIcon
                    sx={{
                      fontSize: "16px",
                      marginRight: "3px",
                    }}
                  />
                  {item.vote_average.toFixed(1)}
                </Avatar>
                <img
                  srcSet={
                    "https://image.tmdb.org/t/p/original/" + item?.poster_path
                  }
                  src={
                    "https://image.tmdb.org/t/p/original/" + item?.poster_path
                  }
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.title || item.name}
                  position="bottom"
                />
              </ImageListItem>
            ))
          : null}
        {movies.length === 0 && !loading && (
          <div>
            <p>Ops! Parece que não encontramos o que você procura.</p>
          </div>
        )}
      </ImageList>
    </Box>
  );
}
