import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { PropsHome } from "../../types";
import { Avatar, ImageListItemBar } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

export default function RecipeReviewCard({ data }: { data: PropsHome }) {
  const handleClick = (id: number, titleprop: string) => {
    const title = titleprop
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    window.location.href = `filme/${id}/assistir-${title}`;
  };
  const vote = Number(data.vote_average.toFixed(1));
  const bgColor = vote >= 7 ? "green" : vote >= 5 ? "#b0b000bf" : "red";

  return (
    <Card
      sx={{
        width: {
          xl: 780,
          lg: 470,
          md: 300,
          sm: 250,
          xs: 300,
        },
        height: {
          xl: 430,
          lg: 330,
          md: 240,
          xs: 200,
        },

        position: "relative",
        marginRight: 2,
        backgroundColor: "black",
        color: "white",
        cursor: "pointer",
      }}
    >
      <Avatar
        sx={{
          bgcolor: bgColor,
          position: "absolute",
          top: 5,
          right: 6,
          width: "51px",
          height: "25px",
          fontSize: "15px",
          display: "flex",
          alignItems: "center",
        }}
        variant="rounded"
      >
        <StarIcon
          sx={{
            fontSize: "16px",
            marginRight: "3px",
          }}
        />
        {vote}
      </Avatar>
      <CardMedia
        component="img"
        image={"https://image.tmdb.org/t/p/original/" + data?.backdrop_path}
        alt={data?.title}
        sx={{
          width: {
            xl: 780,
            lg: 470,
            md: 300,
            sm: 250,
            xs: 300,
          },
          height: {
            xl: 430,
            lg: 330,
            md: 240,
            xs: 200,
          },
          "&& .css-ojekym-MuiCardMedia-root": {
            width: "100%",
            height: "100%",
            objectFit: "cover !important",
          },
        }}
        onClick={() => handleClick(data.id, data.title)}
      />

      <ImageListItemBar title={data.title} position="bottom" />
    </Card>
  );
}
