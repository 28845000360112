import React from "react";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface Props {
  useRefDiv: HTMLDivElement | null;
  left: number;
  right: number;
}

const ButtonScroll = ({ useRefDiv, left, right }: Props) => {
  const handleClickPrev = () => {
    if (useRefDiv !== null) {
      useRefDiv.scrollBy({ behavior: "smooth", left: -500 });
    }
  };
  const handleClickNext = () => {
    if (useRefDiv !== null) {
      useRefDiv.scrollBy({ behavior: "smooth", left: 500 });
    }
  };

  return (
    <div>
      <IconButton
        aria-label="delete"
        size="large"
        sx={{
          position: "absolute",
          color: "white",
          top: "50%",
          zIndex: 99,
          left: left,
        }}
        onClick={handleClickPrev}
      >
        <ArrowBackIosIcon fontSize="inherit" />
      </IconButton>
      <IconButton
        aria-label="delete"
        size="large"
        sx={{
          position: "absolute",
          color: "white",
          top: "50%",
          zIndex: 99,
          right: right,
        }}
        onClick={handleClickNext}
      >
        <ArrowForwardIosIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};

export default ButtonScroll;
