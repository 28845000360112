import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Link, Outlet } from "react-router-dom";
import { Navbar } from "./components/Navbar/Navbar.tsx";
import "./assets/styles/main.scss";
import Popover from "./components/popover/popover.tsx";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <header>
          <Navbar />
        </header>
        <Popover />
        <Outlet />
        <footer
          style={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            marginBlock: 30,
            fontFamily: "Bebas Neue",
          }}
        >
          <span>
            Desenvolvido por Cine
            <span style={{ color: "rgb(177 213 255)" }}>Tok</span>
          </span>
          <span>©2024</span>
          <Link to="/politicaprivacidade">
            <span style={{ color: "rgb(196 191 191 / 80%)", fontSize: "12px" }}>
              Termos e condições
            </span>
          </Link>

          <Link
            to={"https://www.tiktok.com/@cynetok?_t=8pCOP5e6ssf&_r=1"}
            target="_blank"
            style={{
              textAlign: "end",
            }}
            aria-label="Link para perfil no Tiktok"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width={24}
              height={24}
              style={{
                cursor: "pointer",
                marginRight: 10,
              }}
            >
              <path
                fill="#f8f2f2"
                d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"
              />
            </svg>
          </Link>
          <div style={{ width: "100%" }}>
            <Link
              to={"https://www.themoviedb.org"}
              style={{ width: "10%", height: "100%" }}
              target="_blank"
            >
              <img
                src="https://www.themoviedb.org/assets/2/v4/logos/v2/blue_long_1-8ba2ac31f354005783fab473602c34c3f4fd207150182061e425d366e4f34596.svg"
                alt="TMDb logo"
                width={80}
              />
            </Link>
            <p
              style={{
                color: "rgba(196, 191, 191, 0.8)",
                fontSize: "12px",
                margin: 0,
              }}
            >
              This product uses the TMDB API but is not endorsed or certified by
              TMDB.
            </p>
          </div>
        </footer>
      </div>
    </QueryClientProvider>
  );
}

export default App;
