import React from "react";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { fetchMovie } from "../../Services/index.ts";
import { PropsQuery } from "../../types/index.ts";
import Trending from "../Trending/Trending.tsx";
import { useQuery } from "@tanstack/react-query";

const Movie = () => {
  const { id_movie } = useParams<string>();
  const id = Number(id_movie && id_movie?.replace("id_movie=", ""));
  const { data, isLoading } = useQuery<PropsQuery>({
    queryKey: ["homedata"],
    queryFn: () => fetchMovie(id),
  });

  const handleClick = () => {
    window.open(`/watch/${id}/${data?.movie?.title}`, "_blank");
  };

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "356px",
          backgroundImage: `url(https://image.tmdb.org/t/p/original/${data?.movie?.backdrop_path})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          "@media (max-width: 500px)": {
            backgroundAttachment: "inherit",
          },
        }}
      ></Box>
      {isLoading && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Skeleton
            sx={{
              bgcolor: "grey.900",
              maxWidth: {
                sm: 220,
                xs: 120,
              },
              height: {
                md: 300,

                xs: 120,
              },
            }}
            variant="rectangular"
          />
          <Typography variant="h1">{<Skeleton />}</Typography>
        </div>
      )}
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: "center", alignItems: "center", marginBlock: 3 }}
      >
        <Grid item md={4} sx={{ textAlign: "end" }}>
          {data && (
            <img
              src={
                "https://image.tmdb.org/t/p/original/" + data.movie?.poster_path
              }
              alt={data.movie?.title}
              width={300}
              height={"100%"}
              style={{
                objectFit: "fill",
                borderRadius: "10px",
              }}
            />
          )}
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingInline: "5px",
          }}
        >
          <h2 style={{ textAlign: "center" }}>{data?.movie?.title}</h2>
          <ul style={{ marginBlock: 20, paddingInline: 10 }}>
            <li
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Generos:{" "}
              {data &&
                data.movie?.genres.map((item) => (
                  <p style={{ marginInlineStart: 4, marginBlock: 3 }}>
                    {" "}
                    🔸{item?.name}
                  </p>
                ))}
            </li>
            <li style={{ paddingBottom: 2 }}>
              Data de lançamento: {data?.movie?.release_date}
            </li>
            {data?.movie?.runtime && (
              <li style={{ paddingBottom: 2 }}>
                Duração: {Math.floor(data.movie?.runtime / 60)}h:
                {Math.round(data.movie?.runtime % 60)}m
              </li>
            )}

            <li style={{ paddingBottom: 2 }}>
              Nota: {Math.floor(data?.movie?.vote_average)} /10
            </li>
            <li style={{ paddingBottom: 2 }}>
              tagline: {data?.movie?.tagline ? data?.movie?.tagline : "..."}
            </li>
            <li style={{ paddingBottom: 2 }}>
              <p>Sinopse:</p>
              <p style={{ textAlign: "justify" }}>
                {data?.movie?.overview ? data?.movie?.overview : "..."}
              </p>
            </li>
          </ul>
          <Button
            variant="contained"
            size="large"
            sx={{
              padding: "10px 60px",
              justifySelf: "center",
              marginInline: "10px",
            }}
            onClick={handleClick}
          >
            Assistir
          </Button>
        </Grid>
      </Grid>
      {data && data?.recomendation.length !== 0 && (
        <Trending
          h3="Recomendação"
          data={data?.recomendation}
          url="filme/"
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default Movie;
