import React, { useEffect, useState } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { fetchSerie } from "../../Services/index.ts";
import { PropsQuerySerie } from "../../types/index.ts";
import Trending from "../Trending/Trending.tsx";
import AccordionExpandIcon from "../AccordionExpandIcon/AcordionExpandIcon.tsx";
import { useQuery } from "@tanstack/react-query";

const SerieId = () => {
  const { id_serie } = useParams<string>();
  const id = Number(id_serie && id_serie?.replace("id_serie=", ""));

  const { data, isLoading } = useQuery<PropsQuerySerie>({
    queryKey: ["homedata"],
    queryFn: () => fetchSerie(id),
  });
  console.log(data && data.recomendation);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "256px",
          backgroundImage: `url(https://image.tmdb.org/t/p/original/${data?.serie?.backdrop_path})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          "@media (max-width: 500px)": {
            backgroundAttachment: "inherit",
          },
        }}
      ></Box>
      {isLoading && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Skeleton
            sx={{
              bgcolor: "grey.900",
              maxWidth: {
                sm: 220,
                xs: 120,
              },
              height: {
                md: 300,

                xs: 120,
              },
            }}
            variant="rectangular"
          />
          <Typography variant="h1">{<Skeleton />}</Typography>
        </div>
      )}
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: "center", alignItems: "center", marginBlock: 3 }}
      >
        <Grid item md={4} sx={{ textAlign: "end" }}>
          {data && (
            <img
              src={
                "https://image.tmdb.org/t/p/original/" + data.serie?.poster_path
              }
              alt={data.serie?.name}
              width={300}
              height={350}
              style={{ objectFit: "fill", borderRadius: "10px" }}
            />
          )}
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingInline: "5px",
          }}
        >
          <h2 style={{ textAlign: "center" }}>{data?.serie?.name}</h2>
          {data?.serie && (
            <ul style={{ marginBlock: 20, paddingInline: "5px" }}>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Generos:{" "}
                {data.serie?.genres.map((item) => (
                  <p style={{ marginInlineStart: 2, marginBlock: 3 }}>
                    {" "}
                    🔸{item?.name}
                  </p>
                ))}
              </li>
              <li style={{ paddingBottom: 2 }}>
                Data do primeiro episódio: {data.serie?.first_air_date}
              </li>
              {data.serie?.runtime && (
                <li style={{ paddingBottom: 2 }}>
                  Duração: {Math.floor(data.serie?.runtime / 60)}h:
                  {Math.round(data.serie?.runtime % 60)}m
                </li>
              )}
              <li style={{ paddingBottom: 2 }}>
                Temporadas: {Math.floor(data.serie?.number_of_seasons)}
              </li>
              <li style={{ paddingBottom: 2 }}>
                Nota: {Math.floor(data.serie?.vote_average)} /10
              </li>

              <li style={{ paddingBottom: 2 }}>
                tagline: {data.serie?.tagline ? data.serie?.tagline : "..."}
              </li>
              <li style={{ paddingBottom: 2 }}>
                <p>Sinopse:</p>
                {data.serie?.overview ? data.serie?.overview : "..."}
              </li>
            </ul>
          )}
        </Grid>
      </Grid>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxHeight: 500,
          overflow: "auto",
          marginBlock: 10,
        }}
      >
        {data?.serie?.seasons &&
          data?.serie?.seasons.map((item, key) =>
            item.name !== "Especiais" ? (
              <AccordionExpandIcon
                name={item.name}
                seasonNumber={item.season_number}
                id={id}
                key={key}
              />
            ) : null
          )}
      </div>
      {/* <Trending
        h3="Recomendação"
        data={dataRecomendation}
        url={"series/id_serie="}
      /> */}
      {data && data.recomendation.length !== 0 && (
        <Trending
          h3="Recomendação"
          data={data?.recomendation}
          url={"serie/"}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default SerieId;
