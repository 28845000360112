import { createBrowserRouter } from "react-router-dom";

import App from "../App.tsx";
import Filme from "../pages/Filme.tsx";
import { Home } from "../pages/Home.tsx";
import Serie from "../pages/Serie.tsx";
import Movie from "../components/MovieId/MovieId.tsx";
import React from "react";
import SerieId from "../components/SeriesId/SeriesId.tsx";
import Watch from "../components/Watch/Watch.tsx";
import WatchMovie from "../components/WatchMovie/WatchMovie.tsx";
import Search from "../components/Search/Search.tsx";
import TermsAndPolitics from "../components/termsAndPolitics/termsAndPolitics.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/filmes",
        element: <Filme />,
      },
      {
        path: "/series",
        element: <Serie />,
      },
      {
        path: "filme/:id_movie/:title",
        element: <Movie />,
      },

      {
        path: "serie/:id_serie/:title",
        element: <SerieId />,
      },
      {
        path: "search/:name",
        element: <Search />,
      },
      {
        path: "/politicaprivacidade",
        element: <TermsAndPolitics />,
      },
    ],
  },
  {
    path: "/watch/:serie/:season/:episode",
    element: <Watch />,
  },
  {
    path: "/watch/:movie/:name",
    element: <WatchMovie />,
  },
]);

export default router;
