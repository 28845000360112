import { Chip } from "@mui/material";
import React, { useState } from "react";

const ButtonChecked = ({
  label,
  id,
  setPreference,
  removePreference,
}: {
  label: string;
  id: number;
  setPreference: (newPreference: number) => void;
  removePreference: (preferenceToRemove: number) => void;
}) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setPreference(id);
  };

  const handleDelete = () => {
    setClicked(false);
    removePreference(id);
  };

  return (
    <Chip
      label={label}
      key={id}
      variant={clicked ? "filled" : "outlined"}
      onClick={handleClick}
      onDelete={handleDelete}
      sx={{
        backgroundColor: clicked ? "#09a3ad" : "",
        color: "white",
        "& .MuiChip-deleteIcon": {
          color: "#ffffffa3",
        },
        marginRight: 1,
        marginTop: 1,
      }}
    />
  );
};

export default ButtonChecked;
