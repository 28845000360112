import React from "react";
import { Box, Typography } from "@mui/material";

const TermsAndPolitics = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100",
        backgroundColor: "white",
        color: "black",
        textAlign: "justify",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ maxWidth: "1100px", padding: 2 }}>
        <h1 style={{ textAlign: "center" }}>
          Cine<span style={{ color: "rgb(177 213 255)" }}>tok</span>
        </h1>
        <h2> Política de Privacidade</h2>
        <Typography sx={{ whiteSpace: "pre-wrap" }}>
          <br /> <br />
          A sua privacidade é importante para nós. E a política do Cinetok
          respeitar a sua privacidade em relação a qualquer informação sua que
          possamos coletar no site Cinetok e em outros sites que possuímos e
          operamos.
          <br /> <br />
          Solicitamos informações pessoais apenas quando realmente precisamos
          delas para lhe fornecer um serviço. Fazemo-lo por meios justos e
          legais, com o seu conhecimento e consentimento. Também informamos por
          que estamos coletando e como será usado.
          <br /> <br />
          Apenas retemos as informações coletadas pelo tempo necessário para
          fornecer o serviço solicitado. Quando armazenamos dados, protegemos
          dentro de meios comercialmente aceitáveis ​​para evitar perdas e
          roubos, bem como acesso, divulgação, cópia, uso ou modificação não
          autorizados.
          <br /> <br />
          Não compartilhamos informações de identificação pessoal publicamente
          ou com terceiros, exceto quando exigido por lei.
          <br /> <br />
          O nosso site pode ter links para sites externos que não são operados
          por nós. Esteja ciente de que não temos controle sobre o conteúdo e
          práticas desses sites e não podemos aceitar responsabilidade por suas
          respectivas políticas de privacidade.
          <br /> <br />
          Você é livre para recusar a nossa solicitação de informações pessoais,
          entendendo que talvez não possamos fornecer alguns dos serviços
          desejados.
          <br /> <br />
          O uso continuado de nosso site será considerado como aceitação de
          nossas práticas em torno de privacidade e informações pessoais. Se
          você tiver alguma dúvida sobre como lidamos com dados do usuário e
          informações pessoais, entre em contato conosco.
          <br /> <br />
          <h3>
            {" "}
            Uso da API do{" "}
            <b>
              <b>TMDB</b>
            </b>
          </h3>
          <br /> <br />
          Nosso site utiliza a API do The Movie Database (
          <b>
            <b>TMDB</b>
          </b>
          ) para fornecer informações sobre filmes e séries. A API do{" "}
          <b>TMDb</b> é uma ferramenta de terceiros que nos permite acessar
          dados sobre filmes e séries, incluindo sinopses, elencos e datas de
          lançamento.
          <br /> <br />
          Dados Coletados: A API do <b>TMDb</b> nos fornece informações sobre
          filmes e séries, mas não coleta dados pessoais dos usuários. No
          entanto, podemos coletar dados de navegação e interações que não são
          fornecidos diretamente pela API. Uso dos Dados: Utilizamos as
          informações da API do <b>TMDb</b> para enriquecer o conteúdo do nosso
          site e oferecer uma experiência personalizada aos nossos usuários.
          Todos os dados fornecidos pela API do <b>TMDb</b> são usados em
          conformidade com os Termos de Serviço da <b>TMDb</b>. Direitos
          Autorais: Todo o conteúdo fornecido pela API do <b>TMDb</b>, incluindo
          dados e imagens, é protegido por direitos autorais e é propriedade do{" "}
          <b>TMDb</b>. O uso dos dados da API do <b>TMDb</b> está sujeito aos
          Termos de Serviço do <b>TMDb</b> e não é permitido copiar, distribuir
          ou usar qualquer dado da API do <b>TMDb</b> para fins comerciais sem a
          devida autorização do <b>TMDb</b>. Google AdSense e Cookies
          <br /> <br />
          O serviço Google AdSense que usamos para veicular publicidade utiliza
          um cookie DoubleClick para veicular anúncios mais relevantes em toda a
          Web e limitar o número de vezes que um determinado anúncio é exibido
          para você. Para mais informações sobre o Google AdSense, consulte as
          FAQs oficiais sobre privacidade do Google AdSense.
          <br /> <br />
          Utilizamos anúncios para compensar os custos de funcionamento deste
          site e fornecer financiamento para futuros desenvolvimentos. Os
          cookies de publicidade comportamental usados ​​por este site foram
          projetados para garantir que você receba os anúncios mais relevantes
          sempre que possível, rastreando anonimamente seus interesses e
          apresentando coisas semelhantes que possam ser do seu interesse.
          <br /> <br />
          Vários parceiros anunciam em nosso nome e os cookies de rastreamento
          de afiliados simplesmente nos permitem ver se nossos clientes
          acessaram o site através de um dos sites de nossos parceiros, para que
          possamos creditá-los adequadamente e, quando aplicável, permitir que
          nossos parceiros afiliados ofereçam qualquer promoção que possa
          fornecê-lo para fazer uma compra.
          <br /> <br />
          Compromisso do Usuário
          <br /> <br />
          O usuário se compromete a fazer uso adequado dos conteúdos e da
          informação que o Cinetok oferece no site e, com caráter enunciativo,
          mas não limitativo:
          <br /> <br />
          A) Não se envolver em atividades que sejam ilegais ou contrárias à boa
          fé e à ordem pública; B) Não difundir propaganda ou conteúdo de
          natureza racista, xenofóbica, de azar, qualquer tipo de pornografia
          ilegal, apologia ao terrorismo ou contra os direitos humanos; C) Não
          causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do
          Cinetok, de seus fornecedores ou terceiros, para introduzir ou
          disseminar vírus informáticos ou quaisquer outros sistemas de hardware
          ou software que sejam capazes de causar danos anteriormente
          mencionados.
          <br /> <br />
          Mais Informações
          <br /> <br />
          Esperamos que esteja esclarecido e, como mencionado anteriormente, se
          houver algo que você não tem certeza se precisa ou não, geralmente é
          mais seguro deixar os cookies ativados, caso interaja com um dos
          recursos que você usa em nosso site.
          <br /> <br />
          Esta política é efetiva a partir de 30 de agosto de 2024, 23:27.
        </Typography>
        <h2 style={{ marginBlockStart: "50px" }}>Termos e condições</h2>
        <Typography sx={{ whiteSpace: "pre-wrap", marginBlockEnd: "60px" }}>
          1. Termos Ao acessar ao site Cinetok, você concorda em cumprir estes
          termos de serviço, todas as leis e regulamentos aplicáveis ​​e
          concorda que é responsável pelo cumprimento de todas as leis locais
          aplicáveis. Se você não concordar com algum desses termos, está
          proibido de usar ou acessar este site. Os materiais contidos neste
          site são protegidos pelas leis de direitos autorais e marcas
          comerciais aplicáveis.
          <br />
          <br />
          2. Uso de Licença É concedida permissão para baixar temporariamente
          uma cópia dos materiais (informações ou software) no site Cinetok,
          apenas para visualização transitória pessoal e não comercial. Esta é a
          concessão de uma licença, não uma transferência de título e, sob esta
          licença, você não pode:
          <br />
          <br />
          Modificar ou copiar os materiais; Usar os materiais para qualquer
          finalidade comercial ou para exibição pública (comercial ou não
          comercial); Tentar descompilar ou fazer engenharia reversa de qualquer
          software contido no site Cinetok; Remover quaisquer direitos autorais
          ou outras notações de propriedade dos materiais; ou Transferir os
          materiais para outra pessoa ou 'espelhar' os materiais em qualquer
          outro servidor. Esta licença será automaticamente rescindida se você
          violar alguma dessas restrições e poderá ser rescindida pelo Cinetok a
          qualquer momento. Ao encerrar a visualização desses materiais ou após
          o término desta licença, você deve apagar todos os materiais baixados
          em sua posse, seja em formato eletrônico ou impresso.
          <br />
          <br />
          3. Uso da API do <b>TMDb</b> O Cinetok utiliza a API do The Movie
          Database (<b>TMDb</b>) para fornecer informações sobre filmes e
          séries. Todo o conteúdo fornecido pela API do <b>TMDb</b>, incluindo
          dados e imagens, é protegido por direitos autorais e é propriedade do{" "}
          <b>TMDb</b>. O uso dos dados da API do <b>TMDb</b> está sujeito aos
          Termos de Serviço do <b>TMDb</b>. Você não pode usar, copiar,
          modificar ou distribuir qualquer conteúdo fornecido pela API do{" "}
          <b>TMDb</b> sem a devida autorização.
          <br />
          <br />
          4. Isenção de responsabilidade Os materiais no site do Cinetok são
          fornecidos 'como estão'. O Cinetok não oferece garantias, expressas ou
          implícitas, e, por este meio, isenta e nega todas as outras garantias,
          incluindo, sem limitação, garantias implícitas ou condições de
          comercialização, adequação a um fim específico ou não violação de
          propriedade intelectual ou outra violação de direitos. Além disso, o
          Cinetok não garante ou faz qualquer representação relativa à precisão,
          aos resultados prováveis ​​ou à confiabilidade do uso dos materiais em
          seu site ou de outra forma relacionado a esses materiais ou em sites
          vinculados a este site.
          <br />
          <br />
          5. Limitações Em nenhum caso o Cinetok ou seus fornecedores serão
          responsáveis ​​por quaisquer danos (incluindo, sem limitação, danos
          por perda de dados ou lucro ou devido a interrupção dos negócios)
          decorrentes do uso ou da incapacidade de usar os materiais no Cinetok,
          mesmo que Cinetok ou um representante autorizado do Cinetok tenha sido
          notificado oralmente ou por escrito da possibilidade de tais danos.
          Como algumas jurisdições não permitem limitações em garantias
          implícitas, ou limitações de responsabilidade por danos consequentes
          ou incidentais, essas limitações podem não se aplicar a você.
          <br />
          <br />
          6. Precisão dos materiais Os materiais exibidos no site do Cinetok
          podem incluir erros técnicos, tipográficos ou fotográficos. O Cinetok
          não garante que qualquer material em seu site seja preciso, completo
          ou atual. O Cinetok pode fazer alterações nos materiais contidos em
          seu site a qualquer momento, sem aviso prévio. No entanto, o Cinetok
          não se compromete a atualizar os materiais.
          <br />
          <br />
          7. Links O Cinetok não analisou todos os sites vinculados ao seu site
          e não é responsável pelo conteúdo de nenhum site vinculado. A inclusão
          de qualquer link não implica endosso pelo Cinetok do site. O uso de
          qualquer site vinculado é por conta e risco do usuário.
          <br />
          <br />
          8. Modificações O Cinetok pode revisar estes termos de serviço do site
          a qualquer momento, sem aviso prévio. Ao usar este site, você concorda
          em ficar vinculado à versão atual desses termos de serviço.
          <br />
          <br />
          9. Lei aplicável Estes termos e condições são regidos e interpretados
          de acordo com as leis do Cinetok, e você se submete irrevogavelmente à
          jurisdição exclusiva dos tribunais naquele estado ou localidade.
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsAndPolitics;
