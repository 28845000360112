import React from "react";
import { CardPrimary } from "../components/CardPrimary/CardPrimary.tsx";
import { ContainerImage } from "../components/ContainerImage/ContainerImage.tsx";
import { Box } from "@mui/material";

export const Home = () => {
  return (
    <div>
      <Box
        sx={{
          height: {
            xl: "540px",
            md: "300px",
            xs: "162px",
          },
        }}
      >
        <ContainerImage />
      </Box>
      <main>
        <CardPrimary />
      </main>
    </div>
  );
};
