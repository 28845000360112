import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

const WatchMovie = () => {
  const { movie, name } = useParams();
  const movies = Number(movie);
  console.log(process.env.REACT_URL_MOVIE);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h2>Seu player já vai começar</h2>
      <Box
        sx={{
          width: {
            md: 600,
            xs: "90%",
          },
          height: {
            xs: 400,
          },
        }}
      >
        <iframe
          src={`${process.env.REACT_APP_URL_MOVIE}${movies}#noLink`}
          width={"100%"}
          height={"100%"}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={`Video player for movie ${name}`}
        />
      </Box>
    </Box>
  );
};

export default WatchMovie;
