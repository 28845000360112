import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const fetchHome = async () => {
  try {
    const response = await api.get("/");

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchMovies = async (page: number): Promise<any> => {
  try {
    const response = await api.get("/movies/" + page);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchMovie = async (id: number) => {
  try {
    const response = await api.get("/movie/" + id);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSeries = async (page: number) => {
  try {
    const response = await api.get("/series/" + page);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSerie = async (id: number) => {
  try {
    const response = await api.get(`/serie/${id}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSeasons = async (id: number, seasonNumber: number) => {
  try {
    const response = await api.get(`/serie/${id}/${seasonNumber}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSearch = async (name: string | undefined) => {
  try {
    const response = await api.get(`/search/${name}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};
