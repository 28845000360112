import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { fetchSeasons } from "../../Services/index.ts";
import { propsSeason } from "../../types/index.ts";

export default function AccordionExpandIcon({
  name,
  seasonNumber,
  id,
}: {
  name: string;
  seasonNumber: number;
  id: number;
}) {
  const [season, setSeason] = useState<propsSeason>();

  const loadMovies = async () => {
    try {
      const response = await fetchSeasons(id, seasonNumber);

      if (response?.season) {
        setSeason(response?.season);
      }
    } catch (erro) {
      console.log(erro);
    }
  };
  useEffect(() => {
    loadMovies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlClick = (ep: number) => {
    window.open(`/watch/${id}/${seasonNumber}/${ep}`, "_blank");
  };

  return (
    <Accordion
      sx={{
        width: "58%",
        background: "black",
        color: "white",
        borderBottom: "1px solid #1111f0b8",
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon sx={{ color: "white" }} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ width: "58%" }}>
        {season &&
          season.episodes.map((item, key) => (
            <Typography key={key}>
              <p
                onClick={() => handlClick(item.episode_number)}
                style={{ cursor: "pointer" }}
              >
                {item.episode_number} - {item.name}
              </p>
            </Typography>
          ))}
      </AccordionDetails>
    </Accordion>
  );
}
