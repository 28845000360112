import React from "react";
import Trending from "../Trending/Trending.tsx";
import TopFive from "../TopFive/TopFive.tsx";
import { fetchHome } from "../../Services/index.ts";
import { useQuery } from "@tanstack/react-query";
import { Alert, Box } from "@mui/material";

export const CardPrimary = () => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["homedata"],
    queryFn: fetchHome,
  });

  return (
    <div>
      <Trending
        data={data?.dataMovie}
        h3={"Tendências da semana"}
        url="filme/"
        isLoading={isLoading}
      />
      <Box
        sx={{
          width: "100vw",
          height: {
            xl: 500,
            lg: 413,
            md: 300,
            xs: 280,
          },
          paddingBottom: "1px",
          backgroundImage: `url(${process.env.REACT_APP_URL_IMAGE}uDgy6hyPd82kOHh6I95FLtLnj6p.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <TopFive data={data?.dataRated} isloading={isLoading} />
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(0 0 0 / 77%)",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        ></div>
      </Box>
      <Trending
        data={data?.dataSerie}
        h3={"Séries mais assistidas"}
        url="serie/"
        isLoading={isLoading}
      />
      {error && (
        <Alert
          variant="outlined"
          severity="error"
          sx={{ width: "max-content", color: "white", marginInline: "auto" }}
        >
          {error.message}
        </Alert>
      )}
    </div>
  );
};
