import React, { Suspense, useEffect, useRef, useState } from "react";
import RecipeReviewCard from "../CardTopFive/CardTopFive.tsx";
import { Box, Container, Skeleton, Typography } from "@mui/material";
import ButtonScroll from "../Button/Button.tsx";
import { PropsResults } from "../../types/index.ts";

const TopFive = ({
  data,
  isloading,
}: {
  data: PropsResults | undefined;
  isloading: boolean;
}) => {
  const [screen, setScreen] = useState(window.innerWidth <= 900);

  const useRefDiv = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setScreen(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container
      sx={{
        maxWidth: {
          xs: "100%",
        },
        width: "95%",
        overflowX: "hidden",
        marginBlock: 3,
        position: "relative",
        zIndex: 99,
      }}
    >
      <h3>Top Filmes</h3>
      {!screen && (
        <ButtonScroll useRefDiv={useRefDiv.current} left={-9} right={-15} />
      )}
      <Box sx={{ display: "-webkit-box", overflowX: "auto" }} ref={useRefDiv}>
        {isloading || !data
          ? Array.from({ length: 9 }).map(() => (
              <div style={{ marginInlineEnd: "5px" }}>
                <Skeleton
                  sx={{
                    bgcolor: "grey.900",
                    maxHeight: {
                      md: 400,
                      xs: 350,
                    },
                    maxWidth: {
                      md: 500,
                      xs: 400,
                    },
                  }}
                  width={500}
                  height={400}
                  variant="rectangular"
                />
                <Typography variant="h1">{<Skeleton />}</Typography>
              </div>
            ))
          : null}
        {data?.results &&
          data?.results.map((item, key) => (
            <Suspense
              fallback={
                <Skeleton
                  sx={{
                    bgcolor: "grey.900",
                    height: {
                      xs: 300,
                    },
                    maxWidth: {
                      xs: 200,
                    },
                    marginLeft: "5px",
                  }}
                  variant="rectangular"
                  width={300}
                />
              }
            >
              <RecipeReviewCard data={item} key={key} />
            </Suspense>
          ))}
      </Box>
    </Container>
  );
};

export default TopFive;
