import React, { useState } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Box, IconButton } from "@mui/material";
import ButtonChecked from "../ButtonChecked/ButtonChecked.tsx";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export default function BasicButtonGroup({
  setPreference,
  removePreference,
}: {
  setPreference: (newPreference: number) => void;
  removePreference: (preferenceToRemove: number) => void;
}) {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive((prev) => (prev ? false : true));
  };

  return (
    <ButtonGroup
      variant="contained"
      aria-label="Basic button group"
      sx={{
        paddingInline: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // paddingInlineEnd: {
            //   xl: "80px",
            //   lg: "70px",
            //   md: "50px",
            //   sm: "40px",
            //   xs: "10px",
            // },
          }}
        >
          <h3
            style={{
              alignSelf: "start",
              fontFamily: "Bebas Neue",
              fontWeight: 200,
            }}
          >
            Filtro
          </h3>
          <IconButton
            aria-label="Example"
            sx={{ fontSize: "20px", color: "white", cursor: "pointer" }}
            onClick={handleClick}
          >
            <FilterAltIcon />
          </IconButton>
        </Box>
        {active &&
          category.map((item, key) => (
            <ButtonChecked
              label={item.label}
              id={item.id}
              setPreference={setPreference}
              removePreference={removePreference}
              key={key}
            />
          ))}
      </Box>
    </ButtonGroup>
  );
}

const category = [
  { label: "Ação", id: 28 },
  { label: "Aventura", id: 12 },
  { label: "Animação", id: 16 },
  { label: "Comédia", id: 35 },
  { label: "Crime", id: 80 },
  { label: "Documentário", id: 99 },
  { label: "Drama", id: 18 },
  { label: "Família", id: 10751 },
  { label: "Fantasia", id: 14 },
  { label: "Ficção Científica", id: 878 },
  { label: "História", id: 36 },
  { label: "Música", id: 10402 },
  { label: "Mistério", id: 9648 },
  { label: "Romance", id: 10749 },
  { label: "Telefilme", id: 10770 },
  { label: "Terror", id: 27 },
  { label: "Suspense", id: 53 },
  { label: "Guerra", id: 10752 },
  { label: "Faroeste", id: 37 },
];
