import * as React from "react";
import ImageList from "@mui/material/ImageList";
import { fetchSeries } from "../../Services/index.ts";
import { PropsSerie } from "../../types/index.ts";
import { Alert, Button, Skeleton } from "@mui/material";

export default function TitlebarBelowImageList({
  preference,
}: {
  preference: number[];
}) {
  const [series, setSeries] = React.useState<PropsSerie["series"]["results"]>(
    []
  );
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();

  const useRefDiv = React.useRef<HTMLDivElement>(null);

  const LazyImageListItem = React.lazy(
    () => import("../ImageListItem/ImageListItem.tsx")
  );

  const handleClick = (id: number, name: string, titleprops: string) => {
    const title = (titleprops ?? name)
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    window.location.href = `serie/${id}/assistir-${title}`;
  };

  const loadMovies = async (pageNumber: number) => {
    if (loading) return;
    setLoading(true);

    try {
      const response = await fetchSeries(pageNumber);
      if (response?.series?.results) {
        setSeries((prevMovies) => {
          const newMovies = response.series.results.filter(
            (newMovie) => !prevMovies.some((movie) => movie.id === newMovie.id)
          );
          return [...prevMovies, ...newMovies];
        });
      }
    } catch (error) {
      if (error.message) {
        setError(error.message);
      } else {
        setError(error.data);
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadMovies(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const filteredSeries = React.useMemo(() => {
    if (preference.length === 0) {
      return series;
    }
    return series.filter((movie) =>
      preference.some((pref) => movie.genre_ids.includes(pref))
    );
  }, [series, preference]);

  const handlClickLoadingMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    loadMovies(nextPage);
  };

  return (
    <div ref={useRefDiv}>
      <ImageList
        sx={{
          width: "100%",
          height: {
            xs: 470,
          },
          paddingLeft: 0,
          scrollBehavior: "smooth",
          position: "relative",
          display: "grid",
          gridTemplateColumns: {
            xl: "repeat(8, 1fr) !important",
            sm: "repeat(6, 1fr) !important",
            xs: "repeat(4, 1fr) !important",
          },
          "@media (max-width: 1500px) and (min-width: 1300px)": {
            gridTemplateColumns: "repeat(7, 1fr) !important",
          },
          "@media (max-width: 760px) and (min-width: 600px)": {
            gridTemplateColumns: "repeat(5, 1fr) !important",
          },
          "@media (max-width: 400px)": {
            gridTemplateColumns: "repeat(3, 1fr) !important",
          },
        }}
      >
        {loading &&
          Array.from({ length: 20 }).map((_, index) => (
            <Skeleton
              key={index}
              sx={{
                bgcolor: "grey.900",
                maxWidth: {
                  sm: 220,
                  xs: 120,
                },

                height: {
                  md: 300,

                  xs: 120,
                },
              }}
              variant="rectangular"
            />
          ))}
        {filteredSeries &&
          filteredSeries.length > 0 &&
          filteredSeries.map((item, key) => (
            <React.Suspense
              fallback={
                <Skeleton
                  sx={{
                    bgcolor: "grey.900",
                    maxWidth: {
                      sm: 220,
                      xs: 120,
                    },
                    height: {
                      md: 300,

                      xs: 120,
                    },
                  }}
                  variant="rectangular"
                />
              }
              key={key}
            >
              <LazyImageListItem item={item} handleClickUrl={handleClick} />
            </React.Suspense>
          ))}
        {!loading && !(filteredSeries.length > 0) && (
          <div
            style={{
              gridColumn: "1/-1",
              justifySelf: "center",
              alignSelf: "center",
            }}
          >
            <p>Ops! Parece que não encontramos o que você procura.</p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            width: "100%",
            maxHeight: "50px",
            justifyContent: "center",
            marginTop: "40px",
            gap: "10px",
            gridColumn: "1/-1",
            justifySelf: "center",
            alignSelf: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={handlClickLoadingMore}
            disabled={!loading && !(filteredSeries.length > 0)}
          >
            Carregar mais
          </Button>
        </div>
        {error && (
          <Alert
            variant="outlined"
            severity="error"
            sx={{
              width: "max-content",
              color: "white",
              marginInline: "auto",
              gridColumn: "1/-1",
              justifySelf: "center",
              alignSelf: "center",
            }}
          >
            {error}
          </Alert>
        )}
      </ImageList>
    </div>
  );
}
