import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Skeleton, Typography } from "@mui/material";
import ButtonScroll from "../Button/Button.tsx";
import { PropsResults } from "../../types/index.ts";

const Trending = ({
  data,
  h3,
  url,
  isLoading,
}: {
  data: PropsResults | undefined;
  h3: string;
  url: string;
  isLoading: boolean;
}) => {
  const [screen, setScreen] = useState(window.innerWidth <= 900);
  const useRefDiv = useRef<HTMLDivElement | null>(null);
  const RecipeReviewCard = React.lazy(() => import("../Card/Card.tsx"));

  useEffect(() => {
    const handleResize = () => {
      setScreen(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container
      sx={{
        maxWidth: {
          xs: "100%",
        },
        width: {
          xl: "95%",
          md: "inherit",
        },
        overflowX: "hidden",
        margin: "auto",
        position: "relative",
      }}
    >
      <h3>{h3}</h3>
      {!screen && (
        <ButtonScroll useRefDiv={useRefDiv.current} left={-9} right={-15} />
      )}
      <Box sx={{ display: "-webkit-box", overflowX: "auto" }} ref={useRefDiv}>
        {isLoading || !data
          ? Array.from({ length: 9 }).map(() => (
              <div style={{ marginInlineEnd: "5px" }}>
                <Skeleton
                  sx={{
                    bgcolor: "grey.900",
                    height: {
                      xs: 300,
                    },
                    maxWidth: {
                      xs: 200,
                    },
                  }}
                  variant="rectangular"
                  width={300}
                />
                <Typography variant="h1">{<Skeleton />}</Typography>
              </div>
            ))
          : null}

        {data?.results &&
          data?.results.map((item, key) => (
            <React.Suspense
              fallback={
                <Skeleton
                  sx={{
                    bgcolor: "grey.900",
                    height: {
                      xs: 300,
                    },
                    maxWidth: {
                      xs: 200,
                    },
                    marginLeft: "5px",
                  }}
                  variant="rectangular"
                  width={300}
                />
              }
              key={key}
            >
              <RecipeReviewCard data={item} key={key} url={url} />
            </React.Suspense>
          ))}
      </Box>
    </Container>
  );
};

export default Trending;
