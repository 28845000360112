import React, { useCallback, useState } from "react";
import { Grid } from "@mui/material";
import TitlebarBelowImageList from "../components/ImageList/ImageList.tsx";
import BasicButtonGroup from "../components/Category/Category.tsx";

const Filme = () => {
  const [preference, setPreference] = useState<number[]>([]);

  const addPreference = useCallback((newPreference: number) => {
    setPreference((prevPreferences) => {
      if (!prevPreferences.includes(newPreference)) {
        return [...prevPreferences, newPreference];
      }
      return prevPreferences;
    });
  }, []);

  const removePreference = useCallback((preferenceToRemove: number) => {
    setPreference((prevPreferences) =>
      prevPreferences.filter((pref) => pref !== preferenceToRemove)
    );
  }, []);

  return (
    <Grid container spacing={2} sx={{ flexDirection: "column" }}>
      <Grid
        item
        style={{
          borderRight: "1px solid black",
        }}
      >
        <BasicButtonGroup
          removePreference={removePreference}
          setPreference={addPreference}
        />
      </Grid>
      <Grid item>
        <TitlebarBelowImageList preference={preference} />
      </Grid>
    </Grid>
  );
};

export default Filme;
